<!--
 * @Author: wxb
 * @Date: 2021-07-13 00:10:24
 * @LastEditTime: 2021-07-17 01:02:47
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\industry.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/special' }">专题</el-breadcrumb-item>
        <el-breadcrumb-item>行业知识体系专题</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="industry"
           v-show="canSee">
        <div class="industry-category">
          <div class="industry-category-containner">
            <div class="industry-category-title">行业分类</div>
            <div class="industry-category-content">
              <div class="industry-category-content-tree">
                <el-tree class="filter-tree"
                         :data="data"
                         :props="defaultProps"
                         highlight-current
                         @current-change="handleNodeChange"
                         node-key="id"
                         ref="tree">
                </el-tree>
              </div>
            </div>
          </div>
        </div>
        <div class="industry-content">
          <el-tabs v-model="activeName"
                   :before-leave="handleClick">
            <el-tab-pane label="描述"
                         name="first">
              <div class="industry-desc">{{describe}}</div>
            </el-tab-pane>
            <el-tab-pane label="政策背景"
                         name="second">
              <policy-bg :list="policyLst"></policy-bg>
            </el-tab-pane>
            <el-tab-pane label="参考文件"
                         name="third">
              <references :list="referencesLst"></references>
            </el-tab-pane>
            <el-tab-pane label="标准规范"
                         name="fourth">
              <standard-spe :list="standardSpeLst"></standard-spe>
            </el-tab-pane>
            <el-tab-pane label="标准图集"
                         name="fifth">
              <standard-atlas :list="standardAtlasLst"></standard-atlas>
            </el-tab-pane>
            <el-tab-pane label="建设内容"
                         name="sixth">
              <build-content :list="buildLst"
                             :treeData="buildTree"></build-content>
            </el-tab-pane>
            <el-tab-pane label="相关项目"
                         name="seventh">
              <project-rela :list="projectLst"></project-rela>
            </el-tab-pane>
            <el-tab-pane label="软件平台Demo"
                         name="eighth">
              <demo :list="demoLst"></demo>
            </el-tab-pane>
            <el-tab-pane label="工程建设"
                         name="ninth">
              <engineering :list="engineeringLst"></engineering>
            </el-tab-pane>
            <el-tab-pane label="全局概况"
                         name="tenth">
              <industry-system ref="industrySystem"></industry-system>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import dialogviporlogin from '@/components/dialogviporlogin'
import viporlogin from '@/components/viporlogin'
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { industryTree, industryLearning } from '@/api/subject'
import PolicyBg from './components/policyBg.vue'
import References from './components/references.vue'
import StandardSpe from './components/standardSpe.vue'
import StandardAtlas from './components/standardAtlas.vue'
import ProjectRela from './components/projectRela.vue'
import Demo from './components/demo.vue'
import Engineering from './components/engineering.vue'
import BuildContent from './components/buildContent.vue'
import IndustrySystem from './components/industrySystem.vue'
export default {
  name: 'industry',
  components: {
    headerNav,
    footerNav,
    PolicyBg,
    dialogviporlogin,
    viporlogin,
    References,
    StandardSpe,
    StandardAtlas,
    ProjectRela,
    Demo,
    Engineering,
    BuildContent,
    IndustrySystem
  },
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      activeName: 'first',
      canSee: true,
      viporlogin: 'login',
      canSeeVip: false,
      isSelectNode: false,
      describe: '',
      policyLst: [],
      referencesLst: [],
      standardSpeLst: [],
      standardAtlasLst: [],
      projectLst: [],
      demoLst: [],
      engineeringLst: [],
      buildLst: [],
      buildTree: [],
      industryInfo: {}
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    handleClick(activeName) {
      if (!this.isSelectNode) {
        this.$message({
          message: '请选择左侧行业分类',
          type: 'warning'
        })
        return false
      }
      if (!this.canSeeVip) {
        this.$refs.dialogviporlogin.handleShow('vip')
        return false
      }

      if (activeName === 'tenth') {
        this.$refs.industrySystem.refreshTree(this.industryInfo)
      }
    },
    // 处理树形节点改变
    handleNodeChange(data) {
      this.describe = data.describe
      this.isSelectNode = true
      const authObj = authenticate('industryVipInfo', this.memberType)
      if (authObj.flag) {
        this.canSeeVip = true
        // 调用接口获取知识体系下个模块内容
        industryLearning({ industryId: data.id }).then(res => {
          if (res.ok) {
            this.policyLst = res.policyBackgroundList
            this.referencesLst = res.referencesList
            this.standardSpeLst = res.standardSpecificationList
            this.standardAtlasLst = res.standardAtlasList
            this.projectLst = res.projectList
            this.demoLst = res.demoList
            this.engineeringLst = res.engineeringList
            this.buildLst = res.fileList
            this.buildTree = res.systemTree || []
            this.industryInfo = res
            this.$refs.industrySystem.initPage(this.industryInfo)
          } else {
            this.$message.error('获取信息异常，请稍后再试')
          }
        }).catch(err => { console.log(err) })
      } else {
        this.canSeeVip = false
      }
    },
    // 鉴权
    authCheck() {
      const authObj = authenticate('industry', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 初始化页面
    initPage() {
      // 调用行业分类树形结构
      industryTree().then(res => {
        if (res.ok) {
          this.data = res.industryTree
        } else {
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.industry {
  display: flex;
  &-category {
    width: 30rem;

    &-containner {
      border: 0.1rem solid #cfcfcf;
      border-top: 0.3rem solid #2982bf;
      border-bottom: 4rem solid #2982bf;
      min-height: 45rem;
    }

    &-title {
      font-size: 1.6rem;
      color: #2982bf;
      padding: 1rem 0 1rem 1.5rem;
    }

    &-content {
      border-top: 0.1rem solid #cfcfcf;

      &-filter {
        padding: 1rem;
      }

      &-tree {
        padding: 0 1rem 1rem;
        /deep/
          .el-tree--highlight-current
          .el-tree-node.is-current
          > .el-tree-node__content {
          color: #2982bf;
          font-weight: 700;
          background: #fff;
        }
      }
    }
  }

  &-content {
    flex: 1;
    min-height: 75rem;
    padding-left: 1rem;

    .industry-desc {
      padding: 5px;
    }
  }
}
</style>
