<!--
 * @Author: wxb
 * @Date: 2021-07-13 11:32:07
 * @LastEditTime: 2021-07-18 21:09:39
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\components\policyBg.vue
-->
<template>
  <div class="containner">
    <el-table :data="tableData"
              border>
      <template slot="empty">
        <el-empty description="哎呀，未查询到相关信息"></el-empty>
      </template>
      <el-table-column width="50"
                       label="序号"
                       align="center">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="policyBackground"
                       label="标题">
        <template slot-scope="scope">
          <el-button type="text"
                     size="small"
                     @click="handleClickItem(scope.row.id)">{{scope.row.policyBackground}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-content">
      <el-pagination background
                     layout="prev, slot, next"
                     :total="total"
                     @current-change="handleCurrentChange"
                     :current-page.sync="currentPage"
                     :hide-on-single-page="true">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'policyBg',
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list: {
      handler(newData, oldData) {
        this.initPage()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initPage() {
      this.total = this.list.length
      this.tableData = this.list.slice(0, 10)
      this.currentPage = 1
    },
    handleCurrentChange(val) {
      const start = (val - 1) * 10
      const end = start + 10
      this.tableData = this.list.slice(start, end)
    },
    handleClickItem(id) {
      const routeData = this.$router.resolve({
        name: 'libaryDetail',
        params: {
          id,
          type: '3'
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  padding: 10px 0;
  text-align: right;
}
</style>
