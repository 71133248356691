<!--
 * @Author: wxb
 * @Date: 2021-07-13 17:21:28
 * @LastEditTime: 2021-07-18 21:36:58
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\components\buildContent.vue
-->
<template>
  <div class="containner">
    <div class="build">
      <div class="build-category">
        <div class="build-category-containner">
          <div class="build-category-title">建设系统</div>
          <div class="build-category-content">
            <div class="build-category-content-tree">
              <el-tree :data="treeData"
                       :props="defaultProps"
                       node-key="systemId"
                       ref="tree">
              </el-tree>
            </div>
          </div>
        </div>
      </div>
      <div class="build-content">
        <div class="build-content-title">厂商文档</div>
        <div class="build-content-content">
          <el-table :data="tableData"
                    border>
            <template slot="empty">
              <el-empty description="哎呀，未查询到相关信息"></el-empty>
            </template>
            <el-table-column width="50"
                             label="序号"
                             align="center">
              <template slot-scope="scope">{{scope.$index+1}}</template>
            </el-table-column>
            <el-table-column prop="manufactor"
                             label="厂家名称">
            </el-table-column>
            <el-table-column prop="fileName"
                             label="文档名称">
              <template slot-scope="scope">
                <el-button type="text"
                           size="small"
                           @click="handleClickItem(scope.row.fileId)"
                           :title="scope.row.fileName">{{scope.row.fileName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="systemName"
                             label="所属系统">
            </el-table-column>
          </el-table>
          <div class="page-content">
            <el-pagination background
                           layout="prev, slot, next"
                           :total="total"
                           @current-change="handleCurrentChange"
                           :current-page.sync="currentPage"
                           :hide-on-single-page="true">
              <span class="current-page">{{currentPage}}</span>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'buildContent',
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      defaultProps: {
        children: 'children',
        label: 'systemName'
      }
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    treeData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list: {
      handler(newData, oldData) {
        this.initPage()
      },
      immediate: true,
      deep: true
    },
    treeData: {
      handler(newData, oldData) {
        this.treeData = newData
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initPage() {
      this.total = this.list.length
      this.tableData = this.list.slice(0, 10)
      this.currentPage = 1
    },
    handleCurrentChange(val) {
      const start = (val - 1) * 10
      const end = start + 10
      this.tableData = this.list.slice(start, end)
    },
    handleClickItem(id) {
      const routeData = this.$router.resolve({
        name: 'libaryDetail',
        params: {
          id,
          type: '4'
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.build {
  display: flex;
  &-category {
    width: 30rem;

    &-containner {
      border: 0.1rem solid #ebeef5;
      min-height: 45rem;
    }

    &-title {
      font-size: 1.6rem;
      color: #2982bf;
      padding: 1rem 0 1rem 1.5rem;
    }

    &-content {
      border-top: 0.1rem solid #ebeef5;

      &-tree {
        padding: 0 1rem 1rem;
        /deep/
          .el-tree--highlight-current
          .el-tree-node.is-current
          > .el-tree-node__content {
          color: #2982bf;
          font-weight: 700;
          background: #fff;
        }
      }
    }
  }

  &-content {
    flex: 1;
    border: 0.1rem solid #ebeef5;
    min-height: 75rem;
    margin-left: 1rem;
    &-title {
      font-size: 1.6rem;
      color: #2982bf;
      padding: 1rem 0 1rem 1.5rem;
    }

    &-content {
      padding: 0.5rem;
      border-top: 0.1rem solid #ebeef5;
    }
  }
}

.page-content {
  padding: 10px 0;
  text-align: right;
}
</style>
