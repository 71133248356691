<!--
 * @Author: wxb
 * @Date: 2021-07-14 14:38:18
 * @LastEditTime: 2021-08-02 13:43:39
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\components\industrySystem.vue
-->
<template>
  <div class="containner">
    <div class="mind-content"
         id="minder-container">
    </div>
    <div class="tools-list">
      <div class="tools-list-item">
        <a title="移动画布"
           @click="handleHandleExec('Hand')"
           :class="{active:isHand}">
          <i class="el-icon-rank"></i>
        </a>
      </div>
      <div class="tools-list-item">
        <a title="定位根节点"
           @click="handleHandleExec('Camera')">
          <i class="el-icon-location-outline"></i>
        </a>
      </div>
      <div class="tools-list-item">
        <a title="全部展开/收起"
           :class="{active:isExpand}"
           @click="handleHandleExec('ExpandToLevel')">
          <i class=" el-icon-full-screen"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'industrySystem',
  data() {
    return {
      km: null,
      isHand: false,
      isExpand: false
    }
  },
  methods: {
    initPage(params) {
      this.$nextTick(() => {
        if (this.km) {
          this.km = null
          document.getElementById('minder-container').innerHTML = ''
        }
        this.km = new window.kityminder.Minder({
          renderTo: '#minder-container'
        })
        const jsondata = {
          root: {
            data: {
              text: params.industryName
            },
            children: []
          },
          template: 'right',
          theme: 'fresh-green',
          zoom: true
        }

        // 描述
        const describeObj = { data: { text: '描述', expandState: 'collapse' } }
        if (params.describe) {
          describeObj.children = [
            {
              data: {
                text: params.describe
              }
            }
          ]
        }
        jsondata.root.children.push(describeObj)
        // 政策背景
        const policyBackgroundList = params.policyBackgroundList || []
        const policyBackgroundObj = { data: { text: '政策背景', expandState: 'collapse' }, children: [] }
        for (const item of policyBackgroundList) {
          item.nodetype = 'laws'
          const node = {
            data: {
              text: item.policyBackground,
              info: item
            }
          }
          policyBackgroundObj.children.push(node)
        }
        jsondata.root.children.push(policyBackgroundObj)

        // 法律法规
        const referencesList = params.referencesList || []
        const referencesObj = { data: { text: '参考文件', expandState: 'collapse' }, children: [] }
        for (const item of referencesList) {
          item.nodetype = 'laws'
          const node = {
            data: {
              text: item.policyBackground,
              info: item
            }
          }
          referencesObj.children.push(node)
        }
        jsondata.root.children.push(referencesObj)

        // 标准规范
        const standardSpecificationList = params.standardSpecificationList
        const standardSpecObj = { data: { text: '标准规范', expandState: 'collapse' }, children: [] }
        for (const item of standardSpecificationList) {
          // 规范节点
          item.nodetype = 'strandard'
          const node4record = {
            data: {
              text: item.name,
              info: item,
              expandState: 'collapse'
            },
            children: []
          }
          const type = item.type
          const idx = standardSpecObj.children.findIndex(item => item.data.text === type)

          if (idx === -1) {
            // 分类节点
            const node4type = {
              data: {
                text: type,
                expandState: 'collapse'
              },
              children: [node4record]
            }
            standardSpecObj.children.push(node4type)
          } else {
            standardSpecObj.children[idx].children.push(node4record)
          }
        }
        jsondata.root.children.push(standardSpecObj)

        // 标准图集
        const standardAtlasList = params.standardAtlasList
        const standardAtlasObj = { data: { text: '标准图集', expandState: 'collapse' }, children: [] }
        for (const item of standardAtlasList) {
          // 图集节点
          item.nodetype = 'atlas'
          const node4record = {
            data: {
              text: item.name,
              info: item,
              expandState: 'collapse'
            },
            children: []
          }
          const type = item.type
          const idx = standardAtlasObj.children.findIndex(item => item.data.text === type)
          if (idx === -1) {
            // 分类节点
            const node4type = {
              data: {
                text: type,
                expandState: 'collapse'
              },
              children: [node4record]
            }
            standardAtlasObj.children.push(node4type)
          } else {
            standardAtlasObj.children[idx].children.push(node4record)
          }
        }
        jsondata.root.children.push(standardAtlasObj)

        // 建设内容
        const systemTree = params.systemTree || []
        const fileList = params.fileList || []
        const buildContentObj = { data: { text: '建设内容', expandState: 'collapse' }, children: [] }
        this.loopSystem(systemTree, fileList, buildContentObj)
        jsondata.root.children.push(buildContentObj)

        // 相关项目
        const projectList = params.projectList || []
        const projectObj = { data: { text: '相关项目', expandState: 'collapse' }, children: [] }
        for (const item of projectList) {
          // 项目节点
          item.nodetype = 'project'
          const node4record = {
            data: {
              text: item.projectName,
              info: item,
              expandState: 'collapse'
            },
            children: []
          }
          const provinceName = item.provinceName
          const idx = projectObj.children.findIndex(item => item.data.text === provinceName)

          if (idx === -1) {
            const node4type = {
              data: {
                text: provinceName,
                expandState: 'collapse'
              },
              children: [node4record]
            }
            projectObj.children.push(node4type)
          } else {
            projectObj.children[idx].children.push(node4record)
          }
        }
        jsondata.root.children.push(projectObj)

        // Demo平台
        const demoList = params.demoList || []
        const demoObj = { data: { text: '软件平台Demo', expandState: 'collapse' }, children: [] }
        for (const item of demoList) {
          // demo节点
          item.nodetype = 'demo'
          const node4record = {
            data: {
              text: item.name,
              info: item,
              expandState: 'collapse'
            },
            children: []
          }
          const manufactorName = item.manufactorName
          const idx = demoObj.children.findIndex(item => item.data.text === manufactorName)

          if (idx === -1) {
            const node4type = {
              data: {
                text: manufactorName,
                expandState: 'collapse'
              },
              children: [node4record]
            }
            demoObj.children.push(node4type)
          } else {
            demoObj.children[idx].children.push(node4record)
          }
        }
        jsondata.root.children.push(demoObj)

        // 工程建设
        const engineeringList = params.engineeringList || []
        const engineeringObj = { data: { text: '工程建设', expandState: 'collapse' }, children: [] }
        for (const item of engineeringList) {
          item.nodetype = 'engineering'
          const node4record = {
            data: {
              text: item.fileName,
              info: item,
              expandState: 'collapse'
            },
            children: []
          }
          const classificationName = item.classificationName
          const idx = engineeringObj.children.findIndex(item => item.data.text === classificationName)

          if (idx === -1) {
            const node4type = {
              data: {
                text: classificationName,
                expandState: 'collapse'
              },
              children: [node4record]
            }
            engineeringObj.children.push(node4type)
          } else {
            engineeringObj.children[idx].children.push(node4record)
          }
        }
        jsondata.root.children.push(engineeringObj)

        this.km.importJson(jsondata)
        this.km.disable()
        this.km.execCommand('Camera', this.km.getRoot(), 600)
        // this.km.execCommand('Hand')
        // 监听双击事件
        this.km.on('dblclick', el => {
          if (!el.getTargetNode()) {
            return
          }
          const info = el.getTargetNode().data.info
          if (info) {
            const nodetype = info.nodetype
            // 法律法规
            if (nodetype === 'laws') {
              const routeData = this.$router.resolve({
                name: 'libaryDetail',
                params: {
                  id: info.id,
                  type: 3
                }
              })
              window.open(routeData.href, '_blank')
            }
            // 标准规范
            if (nodetype === 'strandard') {
              const routeData = this.$router.resolve({
                name: 'libaryDetail',
                params: {
                  id: info.id,
                  type: 1
                }
              })
              window.open(routeData.href, '_blank')
            }
            // 标准图集
            if (nodetype === 'atlas') {
              const routeData = this.$router.resolve({
                name: 'libaryDetail',
                params: {
                  id: info.id,
                  type: 2
                }
              })
              window.open(routeData.href, '_blank')
            }
            // 厂家文档
            if (nodetype === 'document') {
              const routeData = this.$router.resolve({
                name: 'libaryDetail',
                params: {
                  id: info.fileId,
                  type: 4
                }
              })
              window.open(routeData.href, '_blank')
            }

            // 相关项目
            if (nodetype === 'project') {
              const routeData = this.$router.resolve({
                name: 'builtProInfo',
                params: {
                  id: info.projectId
                }
              })
              window.open(routeData.href, '_blank')
            }

            // 软件平台Demo
            if (nodetype === 'demo') {
              window.open(info.website)
            }

            // 工程建设
            if (nodetype === 'engineering') {
              const valArr = info.enclosure.split('*')
              const length = valArr.length
              const filekey = valArr[length - 1]
              const routeData = this.$router.resolve({
                name: 'preview',
                params: {
                  filekey
                }
              })
              window.open(routeData.href, '_blank')
            }
          }
        })
      })
    },
    handleHandleExec(command) {
      if (!this.km) { return }
      if (command === 'Hand') {
        this.km.execCommand(command)
        this.isHand = !this.isHand
      } else if (command === 'ExpandToLevel') {
        if (this.isExpand) {
          this.km.execCommand('ExpandToLevel', 1)
        } else {
          this.km.execCommand('ExpandToLevel', 9999)
        }
        this.isExpand = !this.isExpand
      } else {
        this.km.execCommand(command)
      }
    },
    // 刷新
    refreshTree(params) {
      this.initPage(params)
    },
    loopSystem(systemTree, fileList, buildContentObj) {
      for (const item of systemTree) {
        // 当前节点信息
        const node4system = {
          data: {
            text: item.systemName,
            info: item,
            expandState: 'collapse'
          },
          children: []
        }

        // 获取本节点是否存在文档
        const filearr = fileList.filter(value => value.systemId === item.systemId)
        // 存在文档信息则构建文档相关的节点信息
        if (filearr.length > 0) {
          // 厂家文档虚拟节点
          const node4facDoc = {
            data: {
              text: '厂家文档',
              expandState: 'collapse'
            },
            children: []
          }
          for (const iterator of filearr) {
            const manufactorName = iterator.manufactorName
            iterator.nodetype = 'document'
            // 厂家文档实例节点
            const node4record = {
              data: {
                text: iterator.fileName,
                info: iterator,
                expandState: 'collapse'
              },
              children: []
            }
            if (manufactorName) {
              const idx = node4facDoc.children.findIndex(it => it.text === manufactorName)
              if (idx === -1) {
                const node4factory = {
                  data: {
                    text: manufactorName,
                    expandState: 'collapse'
                  },
                  children: [node4record]
                }
                node4facDoc.children.push(node4factory)
              } else {
                node4facDoc.children[idx].children.push(node4record)
              }
            } else {
              // 没有厂家时，文档节点直接与厂家文档虚拟节点关联
              node4facDoc.children.push(node4record)
            }
          }
          node4system.children.push(node4facDoc)
        }

        buildContentObj.children.push(node4system)

        // 如果存在子级
        if (item.children) {
          this.loopSystem(item.children, fileList, node4system)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.containner {
  position: relative;
}
.mind-content {
  width: 113rem;
  height: calc(100vh - 250px);
}

.tools-list {
  position: absolute;
  border-radius: 5px;
  right: 5px;
  bottom: 155px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  &-item {
    padding: 5px;
  }
  a {
    font-size: 25px;
    cursor: pointer;

    &:hover {
      color: #0073bc;
    }

    &.active {
      color: #0073bc;
    }
  }
}
</style>
